import { useState } from 'react';

import { LOCALSTORAGE_KEYS } from '~/constants/local_storage';

import { AuthTokens, ImpersonateHook } from './useImpersonate.d';

/**
  The purpose of this hook is to encapsulate the impersonation tokens
  generated on assume identity requests for brands and creators.

  The hook is responsible for store the impersonation tokens received
  when brand/assumeidentity or influencer/assumeidentity endpoints are called.

  It also removes the impersonation tokens when the restoreidentity endpoint
  is called.
**/

export const useImpersonate = (): ImpersonateHook => {
  const [impersonateTokens, setImpersonateTokens] = useState<AuthTokens | null>(
    null
  );

  const getImpersonateTokens = () => {
    if (impersonateTokens) {
      return impersonateTokens;
    } else {
      return {
        accessToken: window.localStorage.getItem(
          LOCALSTORAGE_KEYS.impersonateAccessTokenKey
        ) as string,
        refreshToken: window.localStorage.getItem(
          LOCALSTORAGE_KEYS.impersonateRefreshTokenKey
        ) as string,
      };
    }
  };

  const persistImpersonateTokens = (tokens: AuthTokens | null) => {
    setImpersonateTokens(tokens);
    if (tokens) {
      window.localStorage.setItem(
        LOCALSTORAGE_KEYS.impersonateAccessTokenKey,
        tokens.accessToken
      );
      window.localStorage.setItem(
        LOCALSTORAGE_KEYS.impersonateRefreshTokenKey,
        tokens.refreshToken
      );
    } else {
      window.localStorage.removeItem(
        LOCALSTORAGE_KEYS.impersonateAccessTokenKey
      );
      window.localStorage.removeItem(
        LOCALSTORAGE_KEYS.impersonateRefreshTokenKey
      );
    }
  };

  return {
    getImpersonateTokens,
    setImpersonateTokens,
    persistImpersonateTokens,
  };
};
