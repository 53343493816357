import { Avatar, Box, Flex, Tooltip } from '@chakra-ui/react';
import React, { FC } from 'react';

export interface IconProps {
  brand_name: string;
  profile_image: string;
  brand_id: number;
}

export interface IconListProps {
  iconList: IconProps[];
  callbackSelectIcon?(clickedIcon: number): void;
  selectedBrand: number;
}

export const IconList: FC<IconListProps> = (props: IconListProps) => {
  const { iconList, callbackSelectIcon, selectedBrand } = props;
  return (
    <Flex
      height="100vh"
      direction="column"
      borderRightWidth="1px"
      bg="primary.900"
      width={'16'}
      textAlign={'center'}
      overflowX={'hidden'}
      gap="1"
      css={{
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        'scrollbar-width': 'none',
      }}
    >
      {iconList?.map((icon, idx) => (
        <Box
          key={idx}
          mx="2"
          borderRadius="10"
          _hover={{
            background: 'primary.600',
          }}
        >
          {icon && icon.brand_name && (
            <Tooltip
              label={icon.brand_name}
              placement="right"
              backgroundColor="primary.500"
              marginLeft="2"
              color="white"
              padding="3"
              hasArrow
              borderRadius="8"
              openDelay={500}
            >
              <Flex
                data-cy="brand-sidebar-icon"
                borderRadius="10"
                backgroundColor={
                  icon.brand_id === selectedBrand ? 'primary.400' : ''
                }
                borderColor={
                  icon.brand_id === selectedBrand ? 'primary.300' : ''
                }
                borderWidth={icon.brand_id === selectedBrand ? '1px' : ''}
                justifyContent="center"
                alignItems="center"
                cursor="pointer"
                py="2"
                onClick={() =>
                  callbackSelectIcon && callbackSelectIcon(icon.brand_id)
                }
              >
                <Avatar
                  size="xs"
                  src={icon.profile_image}
                  borderWidth="1px"
                  borderColor="primary.300"
                />
              </Flex>
            </Tooltip>
          )}
        </Box>
      ))}
    </Flex>
  );
};

IconList.displayName = 'IconList';

export default IconList;
