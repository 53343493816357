import { useSSO, USER_ROLES } from '@orca/contexts-sso';
import * as React from 'react';

import { AgencyLayout } from '../AgencyLayout';
import { BrandLayout } from '../BrandLayout';

interface LayoutProps {
  children: React.ReactNode;
}

export default function DashboardLayout({ children }: LayoutProps) {
  const { role } = useSSO();
  switch (role) {
    case USER_ROLES.agency:
      return <AgencyLayout>{children}</AgencyLayout>;
    case USER_ROLES.brandUser:
    case USER_ROLES.brand:
      return <BrandLayout>{children}</BrandLayout>;
    case USER_ROLES.creatorEcho:
    case USER_ROLES.talentRep:
      return <>{children}</>;
    default:
      return <></>;
  }
}
