import { useRouter } from 'next/router';
import React from 'react';

import { LegacyIframe } from '~/components/LegacyIframe';
import Loader from '~/components/Loader';
import { useLegacy } from '~/contexts/legacy';
import DashboardLayout from '~/layouts/DashboardLayout/DashboardLayout';

export function LegacyPage() {
  const { isReady, asPath } = useRouter();
  const { iframeRef, isGlobalLoading } = useLegacy();

  return (
    <DashboardLayout>
      {isGlobalLoading && <Loader />}
      {isReady && (
        <LegacyIframe iframeRef={iframeRef} path={asPath}></LegacyIframe>
      )}
    </DashboardLayout>
  );
}
