import {
  ListResponse,
  ErrorResponse,
  OrcaApi,
  CreateRequest,
  ChangePermissionsRequest,
  CreateResponse,
  ListPayload,
  ListResponseBrands,
} from '@orca/api';
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';

import { QUERIES_KEY } from '~/constants/queries_key';

import { BrandUsersHook } from './useCrudBrandUsersSettings.d';

export const useBrandUsers = (listParams: ListPayload): BrandUsersHook => {
  const createBrandUser = useMutation<
    CreateResponse,
    ErrorResponse,
    CreateRequest
  >(OrcaApi.brandUsers.create);

  const getBrandUserListQuery = useInfiniteQuery<ListResponse, ErrorResponse>({
    queryKey: [QUERIES_KEY.orca.brandUsers.list, listParams.brandId],
    queryFn: ({ pageParam = 1 }) =>
      OrcaApi.brandUsers.list({ ...listParams, page: pageParam }),
    getNextPageParam: (resp: ListResponse) => {
      const currentPage = resp.meta.current_page || 0;
      const totalPages = resp.meta.last_page || 0;
      return currentPage < totalPages ? currentPage + 1 : null;
    },
    refetchOnWindowFocus: false,
  });

  const deleteBrandUser = useMutation(OrcaApi.brandUsers.delete);

  const changeBrandUserPermissions = useMutation<
    {},
    ErrorResponse,
    ChangePermissionsRequest
  >(OrcaApi.brandUsers.changePermissions);

  const getBrandsList = useInfiniteQuery({
    queryKey: [QUERIES_KEY.orca.brands.list],
    queryFn: ({ pageParam = 1 }) => OrcaApi.brands.list(pageParam),
    getNextPageParam: (resp: ListResponseBrands) => {
      const currentPage = resp.meta.current_page || 0;
      const totalPages = resp.meta.last_page || 0;
      return currentPage < totalPages ? currentPage + 1 : null;
    },
    retry: false,
    refetchOnWindowFocus: false,
  });

  return {
    createBrandUser,
    deleteBrandUser,
    changeBrandUserPermissions,
    getBrandUserListQuery,
    getBrandsList,
  };
};
