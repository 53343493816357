import { Flex } from '@chakra-ui/react';
import { useSSO } from '@orca/contexts-sso';
import * as React from 'react';
import { useEffect, useState } from 'react';

import { ECHO_DOMAIN } from '~/constants/env';
import { useImpersonate } from '~/hooks/useImpersonate';

import Loader from './Loader';

interface LegacyIframeProps {
  path: string;
  iframeRef: React.RefObject<HTMLIFrameElement>;
}

export const LegacyIframe = ({ path, iframeRef }: LegacyIframeProps) => {
  const { accessToken, refreshToken } = useSSO();
  const [loading, setLoading] = useState(true);
  const { getImpersonateTokens } = useImpersonate();

  useEffect(() => {
    // This updates the iframe URL whenever the browser history changes to keep both in sync.
    const handlerPopstate = () => {
      (iframeRef.current as HTMLIFrameElement).src = window.location.href
        .replace(window.location.origin, ECHO_DOMAIN)
        .toString();
    };

    window.addEventListener('popstate', handlerPopstate);

    return () => {
      window.removeEventListener('popstate', handlerPopstate);
    };
  }, [iframeRef]);

  if (!accessToken && !refreshToken) {
    return <p>no tokens</p>;
  }

  const handleIframeLoaded = (): void => {
    setLoading(false);
  };

  const url = new URL(`${ECHO_DOMAIN}${path}`);

  const impersonateTokens = getImpersonateTokens();
  if (impersonateTokens?.accessToken) {
    url.searchParams.set('accessToken', impersonateTokens.accessToken);
    url.searchParams.set('refreshToken', impersonateTokens.refreshToken);
  } else {
    url.searchParams.set('accessToken', accessToken);
    url.searchParams.set('refreshToken', refreshToken);
  }

  return (
    <Flex direction="column" w="full" h="full">
      {loading && <Loader />}
      <iframe
        ref={iframeRef}
        width="100%"
        height="100%"
        src={url.toString()}
        onLoad={handleIframeLoaded}
        allow="clipboard-write"
      ></iframe>
    </Flex>
  );
};
