import { Box, Flex } from '@chakra-ui/react';
import React from 'react';

interface LayoutProps {
  children: React.ReactNode;
}

export function AgencyLayout({ children }: LayoutProps) {
  return (
    <Flex w="full" h="full">
      <Box w="full" h="full">
        {children}
      </Box>
    </Flex>
  );
}
