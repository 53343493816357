import { Flex } from '@chakra-ui/react';
import React, { FC } from 'react';

import IconsList, { IconProps } from '../icon-list';

export interface BaseSidebarProps {
  iconList: IconProps[];
  callbackSelectIcon?(clickedIcon: number): void;
  selectedBrand: number;
}

export const Sidebar: FC<BaseSidebarProps> = (props: BaseSidebarProps) => {
  const { iconList, callbackSelectIcon, selectedBrand } = props;

  return (
    <>
      <Flex height="100vh" direction={{ base: 'column', sm: 'row' }}>
        <IconsList
          iconList={iconList}
          callbackSelectIcon={callbackSelectIcon}
          selectedBrand={selectedBrand}
        />
      </Flex>
    </>
  );
};

Sidebar.displayName = 'Sidebar';

export default Sidebar;
