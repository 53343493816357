var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};

// src/base.ts
import { Auth } from "@orca/api-auth";
import axios from "axios";
var instance = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_ORCA_API_RESOURCE}/v1`
});
instance.interceptors.request.use((req) => {
  var _a, _b, _c;
  const accessToken = window.localStorage.accessToken;
  const refreshToken = window.localStorage.refreshToken;
  const excludeAuthorization = !!((_a = req.params) == null ? void 0 : _a.excludeAuthorization) || !!((_b = req.data) == null ? void 0 : _b.excludeAuthorization);
  if (accessToken && refreshToken && !excludeAuthorization) {
    req.headers["X-AUTH-TOKEN"] = `${accessToken}`;
  } else {
    delete req.headers["X-AUTH-TOKEN"];
    (_c = req.params) == null ? true : delete _c.excludeAuthorizationHeader;
  }
  return req;
});
instance.interceptors.response.use(
  (response) => __async(void 0, null, function* () {
    return response;
  }),
  (error) => __async(void 0, null, function* () {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const refreshToken = window.localStorage.getItem(
          "refreshToken"
        );
        const newAccessToken = yield Auth.refreshAccessToken({ refreshToken });
        if (newAccessToken.errorCode) {
          location.href = `${process.env.NEXT_PUBLIC_SSO_DOMAIN}/whalar/login`;
          return Promise.reject(error);
        }
        window.localStorage.setItem(
          "accessToken",
          newAccessToken.accessToken
        );
        instance.defaults.headers.common["X-AUTH-TOKEN"] = newAccessToken.accessToken;
        return instance(originalRequest);
      } catch (error2) {
        window.localStorage.removeItem("accessToken");
        location.href = `${process.env.NEXT_PUBLIC_SSO_DOMAIN}/whalar/login`;
      }
    }
    return Promise.reject(error);
  })
);
function request(method, url, data) {
  return __async(this, null, function* () {
    const response = yield instance[method](
      url,
      method === "get" ? {
        params: data
      } : data
    );
    return response.data;
  });
}
var requests = {
  get(url, data) {
    return __async(this, null, function* () {
      return request("get", url, data);
    });
  },
  post(url, data) {
    return __async(this, null, function* () {
      return request("post", url, data);
    });
  },
  put(url, data) {
    return __async(this, null, function* () {
      return request("put", url, data);
    });
  },
  patch(url, data) {
    return __async(this, null, function* () {
      return request("patch", url, data);
    });
  },
  delete(url, data) {
    return __async(this, null, function* () {
      return request("delete", url, data);
    });
  }
};

// src/constants/index.ts
var ROLES = /* @__PURE__ */ ((ROLES2) => {
  ROLES2["admin"] = "admin";
  ROLES2["user"] = "user";
  return ROLES2;
})(ROLES || {});
var PENDING_SIGNUP_STATUS = /* @__PURE__ */ ((PENDING_SIGNUP_STATUS2) => {
  PENDING_SIGNUP_STATUS2["pending"] = "pending";
  PENDING_SIGNUP_STATUS2["registered"] = "registered";
  PENDING_SIGNUP_STATUS2["notFound"] = "not-found";
  return PENDING_SIGNUP_STATUS2;
})(PENDING_SIGNUP_STATUS || {});

// src/constants/routes.ts
var routes = {
  brandUsersAccess: {
    access: "/brand-users-access",
    accessList: "/brand-users-access/brand",
    changePermissions: "/brand-users-access/permissions"
  },
  brandUsers: {
    brands: "/brand-users/brands",
    pendingSignup: "brand-users/pending-signup",
    validate: "/brand-users/validate",
    validateSignupToken: "/brand-users/validate-signup-token"
  }
};

// src/index.ts
var OrcaApi = {
  brandUsers: {
    create: (payload) => requests.post(
      routes.brandUsersAccess.access,
      payload
    ),
    list: (listParams) => {
      let queryParams = listParams ? `?page=${listParams.page}` : "";
      return requests.get(
        `${routes.brandUsersAccess.accessList}/${listParams.brandId}${queryParams}`
      );
    },
    delete: (brandUserAccessId) => requests.delete(
      `${routes.brandUsersAccess.access}/${brandUserAccessId}`
    ),
    changePermissions: (payload) => requests.patch(
      `${routes.brandUsersAccess.changePermissions}/${payload.brandUserAccessId}`,
      payload
    ),
    pendingSignup: (pendingSignupParams) => {
      let queryParams = pendingSignupParams ? `?email=${encodeURIComponent(pendingSignupParams.email)}` : "";
      return requests.get(
        `${routes.brandUsers.pendingSignup}${queryParams}`
      );
    },
    validateSignupToken: (payload) => requests.get(
      routes.brandUsers.validateSignupToken,
      payload
    ),
    validate: (payload) => requests.post(routes.brandUsers.validate, payload)
  },
  brands: {
    list: (listParams) => {
      let queryParams = listParams ? `?page=${listParams}` : "";
      return requests.get(
        `${routes.brandUsers.brands}${queryParams}`
      );
    }
  }
};
export {
  OrcaApi,
  PENDING_SIGNUP_STATUS,
  ROLES
};
