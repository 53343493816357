import { Box, Flex } from '@chakra-ui/react';
import { ResponseBrands } from '@orca/api';
import React, { useEffect, useState } from 'react';

import { Sidebar } from '~/components/Sidebar';
import { ECHO_DOMAIN } from '~/constants/env';
import { useLegacy } from '~/contexts/legacy';
import { useBrandUsers } from '~/hooks/useCrudBrandUsersSettings';

interface LayoutProps {
  children: React.ReactNode;
}

export function BrandLayout({ children }: LayoutProps) {
  const { me, switchBrand, iframeRef } = useLegacy();
  const [agencyBrands, setAgencyBrands] = useState<ResponseBrands[]>([]);
  const {
    getBrandsList: { data, isLoading, fetchNextPage, hasNextPage },
  } = useBrandUsers({ brandId: me?.brand.brandId as string });

  // Join all brands in same array by fetching all pages
  useEffect(() => {
    setAgencyBrands(
      data?.pages.map(page => page.results).flat() as ResponseBrands[]
    );
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [data, fetchNextPage, hasNextPage]);

  const callbackSelectIcon = async (clickedId: number) => {
    await switchBrand(clickedId.toString());
    (iframeRef.current as HTMLIFrameElement).src = `${ECHO_DOMAIN}/dashboard`;
  };

  return (
    <Flex w="full" h="full">
      {!isLoading && agencyBrands?.length > 1 && (
        <Sidebar
          iconList={agencyBrands}
          callbackSelectIcon={callbackSelectIcon}
          selectedBrand={parseInt(me?.brand?.brandId as string)}
        />
      )}
      {!isLoading && (
        <Box w="full" h="100vh">
          {children}
        </Box>
      )}
    </Flex>
  );
}
